import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";

import LocationImgSlider from "../components/slider-location-img";
// import AccomodationSlider from "../components/slider-accomodation";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const Location = ({ data }) => {
  // const { accomodation, duomo } = data;
  const { duomo } = data;

  return (
    <Layout>
      <Seo
        title={`Location`}
        description={`Palazzo Giureconsulti: un luogo storico nel cuore di Milano.`}
      />

      <div className="box-intro">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/location.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__claim">
                  <p>
                    Palazzo Giureconsulti, da 400 anni è al centro di Milano e della vita sociale,
                    economica, culturale della città.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="palazzo-giureconsulti" className="section section--grey-dark">
        <div className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">Palazzo Giureconsulti</h2>
                <div className="box-article__text">
                  <p>
                    A pochi passi da Piazza Duomo, con la sua facciata elegante e la torre
                    dell'orologio, è una delle location più eleganti, affascinanti e iconiche della
                    città, con una superficie complessiva di 4000 m 2 su quattro piani.
                  </p>

                  <p>
                    Edificato su progetto di Vincenzo Seregni, fu inaugurato nel 1654: ha da sempre
                    rappresentato un punto di riferimento per la comunità degli affari milanese e ha
                    ospitato la prima Borsa Valori di Milano (1809). Dal 1911 è di proprietà della
                    Camera di commercio di Milano Monza Brianza Lodi.
                  </p>

                  <p>E' il più antico e centrale centro congressi della città.</p>

                  <p>
                    Dopo lavori di restauro che hanno interessato la facciata e i principali spazi
                    interni, fra cui la reception e la prestigiosa Sala Colonne, Giureconsulti si
                    propone come luogo di incontro, di scambio e di comunicazione per le communities
                    della città impegnate nel rendere Milano sempre più attrattiva.
                    Un'interpretazione, al passo con lo spirito di questo tempo, del ruolo che è
                    stato del Palazzo nel corso dei secoli: non solo una location, ma un touchpoint
                    che contribuisce alla promozione e allo sviluppo di chi investe su Milano.
                  </p>

                  <div className="d-flex justify-content-between align-items-center pt-3">
                    <div>
                      <strong>Palazzo Giureconsulti</strong>
                      <br />
                      <span className="font-seconday font-small">
                        Piazza Mercanti, 2 20121 Milano (Italia)
                      </span>
                    </div>
                    <Link to="/come-arrivare/" className="btn-link btn-link--small">
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Come <span className="d-block bold">arrivare</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GatsbyImage
            image={getImage(duomo)}
            alt="img duomo"
            objectPosition="center center"
            className="box-article__img box-article__img--duomo d-none d-xl-block"
          />
        </div>

        <LocationImgSlider />
      </section>

      {/* <AccomodationSlider items={accomodation} /> */}

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Location;

export const query = graphql`
  query {
    accomodation: accomodationJson {
      accomodation {
        title
        stars
        distance
        phone
        email
        site
        address
        cap
        city
        linkmap
        image {
          childImageSharp {
            gatsbyImageData(width: 175, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
    duomo: file(relativePath: { eq: "img-duomo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 338, layout: CONSTRAINED)
      }
    }
  }
`;
